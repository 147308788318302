import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import {
	Section,
	SectionActions,
	SectionBody,
	SectionFooter,
	SectionHeader,
	SectionTitle,
} from '@playbooks/interface/sections';
import { repoParams } from 'api/params';
import { RepoDisplayActions } from 'components/repo/repo-display-actions';
import { RepoList } from 'components/repo/repo-list';
import { useStore } from 'contexts';
import { useQuery } from 'hooks';
import { Pagination } from 'molecules/paginations';
import { AlgoliaService } from 'services';

const RepoDisplaySection = ({
	type = 'display',
	title,
	api,
	search,
	rootApi = '',
	rootLink = '',
	options,
	views,
	pagination,
	tailwind,
}) => {
	const [repos, setRepos] = useState([]);
	const [params, setParams] = useState({ ...repoParams, view: 'recent' });
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const [query, setQuery] = useState('');
	const router = useRouter();
	const store = useStore();

	// Computed
	const client = new AlgoliaService({ tableName: 'repos' });

	const routerQuery = useMemo(() => {
		const query = {};
		Object.keys(router.query)
			.filter(key =>
				[
					'tier',
					'variant',
					'view',
					'meta.avgRating',
					'frameworks.uuid',
					'languages.uuid',
					'platforms.uuid',
					'tools.uuid',
					'topics.uuid',
				].includes(key),
			)
			.map(key => (query[key] = router.query[key]));
		return query;
	}, [router.query]);

	// Hooks
	useEffect(() => {
		fetchData();
	}, [router.query, query, params.page]);

	useEffect(() => {
		if (pagination?.type === 'btn') onFetchMore();
	}, [params.page]);

	// Actions
	const [fetchData, loading] = useQuery(async () => {
		if (pagination?.type === 'btn' && params.page !== 0) return;
		const response = query ? await searchDb() : await fetchDb();
		setRepos(response.data);
		setMeta(response.meta);
	});

	const [onFetchMore, taskRunning] = useQuery(async () => {
		if (params.page === 0) return;
		const response = query ? await searchDb() : await fetchDb();
		setRepos(repos.concat(response.data));
		setMeta(response.meta);
	});

	// Methods
	const fetchDb = async () => {
		return await store.query({ url: `${rootApi}/repos`, params: { ...params, ...api, ...routerQuery } });
	};

	const searchDb = async () => {
		return await client.queryIndex(query, { ...params, ...search, ...routerQuery });
	};

	// Render
	return (
		<Section border='' borderRadius='' spacing='py-4' {...tailwind?.section}>
			<SectionHeader border='' spacing='py-4' {...tailwind?.header}>
				{title ? title : <SectionTitle>{meta.totalRecords || repos.length} Results</SectionTitle>}
				<SectionActions {...tailwind?.actions}>
					<RepoDisplayActions
						params={params}
						setParams={setParams}
						query={query}
						setQuery={setQuery}
						options={options}
						views={views}
					/>
				</SectionActions>
			</SectionHeader>
			<SectionBody size='lg' spacing='py-4' {...tailwind?.body}>
				<RepoList type={type} repos={repos} loading={loading} rootLink={rootLink} tailwind={tailwind?.list} />
			</SectionBody>
			<SectionFooter border='' spacing='py-4' {...tailwind?.footer}>
				<Pagination meta={meta} params={params} setParams={setParams} taskRunning={taskRunning} {...pagination} />
			</SectionFooter>
		</Section>
	);
};

export { RepoDisplaySection };
